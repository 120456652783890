.body{
    background :red
}
.option option[disabled] { background-color : #eee}

.notetextarea textarea{
    width:100%;
    margin-top: 3px;
    border:1px solid #eee;
    padding:5px
}
.spanDate{
    margin-left:10px;
    margin-right:10px;
    font-weight: 600;
}
.holidayPicker{
    margin-top:15px;
}
.textareanote{
    width:100%;
    min-height: 100px;
}

.cursor{
    cursor:pointer;
}
