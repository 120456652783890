.yr-dropdown{
    margin-left: 10px;
    color: #999;
}
.ui.accordion .accordion .active.title .angle.right.icon, .ui.accordion .active.title .angle.right.icon {
    transform: rotate(90deg);
}
.ui.accordion .title:not(.ui) {
    padding: .5em 0;
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    font-size: 1.12rem;
    font-weight: 500;
    color: #868688;
    border-top: 1px solid #ddd;
    padding: 15px 20px;
}
.ui.accordion .title:first-child:not(.ui) {
    border-top: 0;
}
.title .angle.right.icon{color: #087ab5 !important;font-weight: 500;}
.kt-notification-v2 {    
    padding: 0 20px;
}

.mh-oneChatContainer .chat-img {
    background: white;
}
.mh-leftBarChatMessage .editorexist {
    width: 189px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

i.circular.icon {
    box-shadow: 0 0 0 0.1em rgb(6, 147, 175) inset !important;
    margin: 6px !important;
}