.mh-playbook-table .btn.btn-icon{
    justify-content:end !important;
}

.mh-playbook-table .dropdown-menu.show{
    top: 2px !important;
    left: 50px !important;

}

.mh-cusrorAuto{
    cursor:auto !important;
}

.mh-bucketNameTag{
    width: auto;
    background: #387ab5;
    color: white;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    margin: 1px;
    line-height: 0.9;
    /* max-width: 200px; */
    /* white-space: nowrap; */
    word-break: break-word;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
}

.mh-bucketNaming{
    max-width: 120px !important;
    white-space: unset !important;
    text-overflow: unset !important;
    overflow: unset !important;
    word-wrap: break-word;
}


.mh-playbook-table i {
    margin-right: 2px;
    font-size: 11px;;
}

.gjs-cv-canvas{
    left:auto !important;
    right: 0px;
}

 .gjs-pn-views-container{
  left:0px !important;
  margin-top: 40px;
}

.gjs-fonts::before{
    font-size: 3em !important;
}

.gjs-block{
    margin: 2px 2.5% 5px !important;
    min-height: 60px !important;
}

.gjs-pn-views-container{
    padding:0px !important

}

#gjs-rte-toolbar, .gjs-bg-main, .gjs-block, .gjs-clm-select option, .gjs-clm-tags .gjs-sm-colorp-c, .gjs-editor, .gjs-mdl-dialog, .gjs-nv-item .gjs-nv-title-c, .gjs-off-prv, .gjs-pn-panel, .gjs-select option, .gjs-sm-sector .gjs-sm-colorp-c, .gjs-sm-select option, .gjs-sm-unit option, .sp-container {
    background-color: #e9eef2!important;
}

.gjs-blocks-cs {
    background-color: #e6e6e6 !important;
}
.gjs-block{
    color:while !important;
}

#kt_portlet_base_demo_1_1_tab_content .fa{
    color: #fff!important;
    font-size: 14px!important;
}

.gjs-two-color, #kt_portlet_base_demo_1_1_tab_content .fa {
    color: #1d1735 !important;
}

.gjs-pn-btn{
    padding-top: 9px !important;
}

.cke_notifications_area{
    position: fixed !important;
    left:40% !important;
    top:100px !important;
}

.cke_notification_message{
    color: white !important;
}

/* .gjs-frame-wrapper{
    top:4px !important;
} */

.gjs-pn-views{
    width: 16%;
    border-bottom: none !important;
}
.gjs-pn-options {
    right: 20%;
}

.gjs-one-bg {
    background-color: #e9eef2 !important;
}

.gjs-pn-panels {
    width: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer, .gjs-clm-tags .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer {
    opacity: 1;
}

.gjs-sm-layer>#gjs-sm-move{
    opacity: 1;
      filter: alpha(opacity=100);   
  }

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer{
    top:4px
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer>#gjs-sm-preview-box{
    right:24px;
}
.gjs-four-color {
    color: #000000;
}

.gjs-sm-sector .gjs-sm-stack #gjs-sm-add{
    color: #000 !important;
}
.gjs-sm-sector .gjs-sm-property{
    padding:0px !important;
}
.gjs-mdl-dialog{
    background-color: #fafafa!important;
}
.gjs-mdl-header{
    color: #727272;
}
.gjs-mdl-title {
    font-size: 16px;
    font-weight: 600;
}

button.gjs-btn-prim.gjs-btn-import {
    background: #cfcdcd;
    padding: 10px 20px;
    color: #333;
    margin-top: 2rem;
}
.gjs-mdl-dialog.gjs-one-bg.gjs-two-color .form-group {
    margin-bottom: 0rem;
}

#kt_portlet_base_demo_1_1_tab_content i.gjs-layer-caret.fa.fa-chevron-right.fa-chevron-down {
    padding: 1px 0;
    font-size: 10px !important;
}

.sp-container{
    position: fixed !important;
    top: 267px !important;
    left: 50% !important;
}

#kt_portlet_base_demo_1_1_tab_content .gjs-toolbar .fa {
    color: #ffffff!important;
    font-size: 12px!important;
}

.gjs-mdl-content .form-control{
    border: 1px solid #aaaaaa;
}

.gjs-mdl-content .form-group label {
    margin-top: 10px;
    font-size: 1.12rem;
    font-weight: 500;
    color: #404041;
}

.gjs-sm-icon {
    color: black;
}

#gjs-sm-input-holder option{
    color: black !important;
}

.gjs-sm-sector .gjs-sm-field input, .gjs-clm-tags .gjs-sm-field input, .gjs-sm-sector .gjs-clm-select input, .gjs-clm-tags .gjs-clm-select input, .gjs-sm-sector .gjs-clm-field input, .gjs-clm-tags .gjs-clm-field input, .gjs-sm-sector .gjs-sm-field select, .gjs-clm-tags .gjs-sm-field select, .gjs-sm-sector .gjs-clm-select select, .gjs-clm-tags .gjs-clm-select select, .gjs-sm-sector .gjs-clm-field select, .gjs-clm-tags .gjs-clm-field select{
    color:black !important;
}

.gjs-f-b1{
    order: 1;
}
.gjs-f-b2{
    order: 2;

}
.gjs-f-b3{
    order: 3;
   
}
.gjs-f-b37{
    order: 4;

}
.gjs-f-button{
    order: 5;

}
.gjs-f-divider{
    order: 6;

}

.gjs-f-text{
    order: 7;

}
.gjs-f-h1p{
    order: 8;

}

.gjs-f-image{
    order: 9;

}
.fa-quote-right{
    order: 11;

}
.fa-link{
    order: 12;

}

.fa-th{
    order: 13;

}

.fa-th-list{
    order: 14;

}

.fa-youtube-play{
    order: 10;

}

.fa-youtube-play:before{
    font-size: 28px ;
}

#gjs-tools{
    z-index: 888 !important;
}

#gjs-tools{
    z-index: 777 !important;
}

.gjs-btnt.gjs-pn-active, .gjs-color-active, .gjs-pn-btn.gjs-pn-active, .gjs-pn-btn:active, .gjs-block:hover{
    color: #087ab6 !important;
}

#kt_portlet_base_demo_1_1_tab_content .fa:hover{
    color: #087ab6 !important;
}
.gjs-pn-commands{
    box-shadow: none !important;
}

.gjs-radio-item label {
    margin: 0 ;
}

.fa-quote-right:before {
    font-size: 17px
}


.fa-chain:before, .fa-link:before {
    font-size: 20px
}

.fa-th:before {
    font-size: 21px
}

.fa-th-list:before{
    font-size:21px;
}

.gjs-pn-buttons .fa-bars{
    display:none;
}

.gjs-pn-options{
    right:11% !important;
}

.gjs-pn-views{
    width:12% !important;
}
.active_branch{
    border: 1px solid rgb(15, 98, 254);
}
.checboxrules{
    margin: 0px !important;
}
.textrule{
    vertical-align: top;
}
.calllistrule{
    border-top: 1px solid #ccc;
    padding-top: 20px;
}
.calllistsecond{
    padding-bottom:10px
}

.otherbuttonlinks{
    max-width:150px;
}
.activebuttonlink{
    background: linear-gradient(
        45deg
        , #087ab6, #00d39e) !important;
            color: #fff !important;
            padding: 5px;
            border-radius: 5px;
}

/*//@Rohit*/
.analiytics-link-email >  a {

    color: #212529  !important;
}

.nextemaillinkradio .kt-radio > span {
    border: 1px solid #1f7ab6;
}
.nextemaillinkradio .kt-radio > span:after {
    border: solid #1f7ab6;
    background: #1f7ab6;
}
.nextemaillinkradio .kt-radio > input:checked ~ span {
    border: 1px solid #1f7ab6;
}

.mh-scheduleTime .kt-radio
{
    display: inline !important;
    margin-right:0px !important;
}

.mh-scheduleTime .align-label{
    margin-left: -7px !important;
}

.react-datepicker__input-container input{
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.mh-datePickerWrapperInTemplate .react-datepicker-wrapper{
    width:100%;
}

.react-datepicker-wrapper{
    width:100%
}

.error-border-red-class{
    border: solid 1px red !important;
}

@media (max-width: 1439px){
.mh-ktContainer {
   
    width: 95% !important;
}
}

.form-control-2 > .default.text{
    color: #787878 !important;    
}
.form-control-2 > .search{
    padding-left: 6px !important;
}

.form-control-2{
    display: block !important;
    width: 100% !important;
    min-height: unset !important;
    padding: 6px !important;
    line-height: 1.42857143 !important;
    
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    /* -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; */
}


span.brand {
    display: none;
}

#grapeJsContainer iframe{
    min-width: auto !important;
}





.zyr-chat-box .radius-20{
    border-radius: 50% !important;
}
.zyr-chat-box{
    background-color: #fff !important;
    border-radius: 3px !important;
}

.zyr-close-icon img{
    width: 25px !important;
}

.btn-icon.zyr-btn-icon i{
    font-size: 13px !important;
}
.btn-icon.zyr-btn-icon{
    width: 2rem !important;
    height: 2rem !important;
}


.small-title.zyr-title{
    font-size:11px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.footer_modal .modal-dialog{
    min-width:80% !important;
}
.footer_text iframe
{
    position: relative;
    height: 100% !important;
    width: 100% !important; 
    min-width:100%  !important;
}
#u_body {background-color: white !important;}
.alt_val_span{
 cursor:pointer
}
.contenteditable{
    padding: 0.5rem 1rem 0.5em 0.1em;
    color: #495057;
}

.right-action-btns .btn:hover{
    color: #444;
}
.right-action-btns button:disabled{
    pointer-events: auto;
    background-color: transparent
}
.not-allowed {cursor: not-allowed;}


#campaignBranchTabs{
    flex-direction: column;
    border-bottom: none;
    height: 100%;
    border:1px solid #0001;
    border-right: none;
}

#campaignBranchTabs a{
    padding: 7px 10px;
    min-width: 70px;
    width: max-content;
    text-align: center;
    position: relative;
    color: #000;
    font-size: 12px;
    font-weight: 300;
}

#campaignBranchTabs a.active{
    background-color: #444;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 0;
}
#campaignBranchTabs + .tab-content .tab-pane{
    height: 100%;
    padding: 1rem;
    font-size: 12px;
}

#campaignBranchTabs a.active:before{
    content: '';
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    border: 7.5px solid transparent;
    border-right-color: #f8f9fa;
    width: 0;
    height: 0;
}

#scheduleBranchTable th:not(last-child){
    min-width: 150px;
}

#scheduleBranchTable td:last-child{
    text-align: center;
}

#scheduleBranchTable th:last-child{
    min-width: 100px;
}

.ui__selector .icon_cancel{
    opacity:.6;
}
.scheduledbrach{
    border:1px solid rgb(255, 205, 20) !important;
}